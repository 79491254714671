import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Apartment } from '../models/apartment/Apartment';
import { Router } from '@angular/router';
import { Backup } from '../models/apartment/Backup';
import { FirebaseFunctionsService } from '../services/firebase-functions.service';
import { BackupResponse } from '../models/apartment/BackupResponse';

@Component({
  selector: 'apartment',
  templateUrl: './apartment.component.html',
  styleUrls: ['./apartment.component.scss']
})
export class ApartmentComponent implements OnChanges {
  @Input() apartment: Apartment;
  isCollapsedBackup = true;
  backups: Backup[] = [];
  backupsLoading = false;
  feedTooltipClass: string;

  constructor(
    public router: Router,
    private functions: FirebaseFunctionsService,
  ) { }

  ngOnChanges(_: SimpleChanges): void {
    if (this.apartment) {
      this.feedTooltipClass = this.getFeedTooltipClass(this.apartment.feed);
    }
  }

  public toggleCollapseStateBackups = async (): Promise<void> => {
    if (this.backups.length === 0) {
      this.backupsLoading = true;
      this.backups = await this.mapBackupsFromApiResult(await this.functions.getBackups(this.apartment.dssId));
      this.backupsLoading = false;
    }
    this.isCollapsedBackup = !this.isCollapsedBackup;

  }

  private getFeedTooltipClass(feed: string): string {
    switch (feed.toLowerCase()) {
      case 'production':
        return 'fa fa-heartbeat text-success';
      case 'beta':
        return 'fa fa-heart text-success';
      case 'alpha':
        return 'fa fa-heart-o text-warning';
      default:
        return 'fa fa-flask text-danger';
    }
  }

  public downloadBackup = async (filename: string) => {
    const data = await this.functions.downloadBackup(filename);

    // save for other browsers: Chrome, Firefox
    const a: any = document.createElement('a');
    document.body.appendChild(a);

    a.style = 'display: none';
    const blob = new Blob([data], { type: 'application/zip' });

    const url = window.URL.createObjectURL(blob);

    a.href = url;

    filename = filename.trim();

    if (filename.toLowerCase().endsWith('.tar.gz')) {
      filename = filename
        .substring(0, filename.length - 7)
        .replace('.', '_') + '.dss';
    }

    a.download = filename;

    a.click();
    window.URL.revokeObjectURL(url);
  }

  public async getBackups(apartment: Apartment) {
    return (await this.functions.getBackups(apartment.dssId)).data.attributes.backups;
  }

  private async mapBackupsFromApiResult(backupMetadata: BackupResponse): Promise<Backup[]> {
    const backups: Backup[] = [];
    backupMetadata.data.attributes.backups.forEach(e => {
      const backup: Backup = {
        backupDateTime: e.attributes.backupDateTime,
        filename: e.attributes.filename,
        name: e.attributes.name
      };

      if (this.apartment.createdAt && Date.parse(new Date(this.apartment.createdAt).toDateString()) < Date.parse(backup.backupDateTime)) {
        backups.push(backup);
      }
    });

    return backups;
  }
}
