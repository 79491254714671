import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MessagesService } from '../services/messages.service';
import { Router } from '@angular/router';
import { SpinnerService } from '../services/spinner.service';
import { ProviderService } from '../services/provider.service';
import { FirebaseFunctionsService } from '../services/firebase-functions.service';
import { AuthService } from '../services/auth.service';
import { EmailMatchValidatorService } from '../services/email-match-validator.service';

interface FormData {
  email: FormControl<string>;
  emailConfirmation: FormControl<string>;
  password: FormControl<string>;
  dSSDisconnected: FormControl<string>;
}

@Component({
  selector: 'change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss']
})
export class ChangeEmailComponent implements OnInit {
  public changeEmailForm: FormGroup<FormData>;

  constructor(
    private auth: AuthService,
    private formBuilder: FormBuilder,
    private messages: MessagesService,
    private router: Router,
    private spinner: SpinnerService,
    private providerService: ProviderService,
    private functions: FirebaseFunctionsService,
    private emailMatchValidatorService: EmailMatchValidatorService
  ) {
    this.buildChangeEmailForm();
  }

  ngOnInit(): void {
    this
      .providerService
      .getProviderIds()
      .then(providers => {
        if (providers.length > 0) {
          this.router.navigateByUrl('/profile');
          return;
        }
      });
  }

  public async changeEmailAction() {
    const formValues = this.changeEmailForm.value;
    const password = formValues.password;
    const newEmail = formValues.email;

    const currentUser = this.auth.getCurrentFirebaseUser();
    const oldEmail = currentUser.email;
    const uid = currentUser.uid;

    this.spinner.show();

    if (oldEmail === newEmail) {
      this.messages.setMessage('ChangeEmail.EmailsEqual');
      this.spinner.hide();
      return;
    }

    try {
      const result = await this.auth.fetchSignInMethodsForEmail(newEmail);
      if (result.length !== 0) {
        this.messages.setMessage('ChangeEmail.EmailAlreadyInUse');
        this.spinner.hide();
        return;
      }
      const user = await this.functions.getUserAccount(uid);

      if (!await this.signInWithEmailAndPassword(oldEmail, password)) {
        return false;
      }

      await this.auth.updateEmail(currentUser, newEmail);

      let state = 'Created';
      if (user.state.toLowerCase() === 'complete' || user.state.toLowerCase() === 'changedemail') {
        state = 'ChangedEmail';
      }
      
      let beta = user.beta;
      if (beta === null || beta === undefined) {
        beta = false;
      }

      await this.updateUserAccount(uid, newEmail, state, beta, oldEmail);
    }
    catch (error) {
      this.spinner.hide();
      this.messages.pushErrorMessage('ChangeEmail.Failed');
    }
    finally {
      this.spinner.hide();
    }
  }

  private buildChangeEmailForm() {
    this.changeEmailForm = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]],
      emailConfirmation: ['', [Validators.email, Validators.required]],
      password: ['', [Validators.required]],
      dSSDisconnected: ['', [Validators.required]]
    }, {
      validators: this.emailMatchValidatorService.validator
    });
  }

  private async signInWithEmailAndPassword(email: string, password: string): Promise<boolean> {
    try {
      await this.auth.confirmPassword(email, password);
      return true;
    }
    catch (error) {
      this.spinner.hide();
      this.messages.setErrorMessage('ChangeEmail.SignInFailed');
      return false;
    }
  }

  private async updateUserAccount(uid: string, newEmail: string, state: string, beta: boolean, oldEmail: string) {
    try {
      await this.functions.updateUserAccount({
        uid,
        email: newEmail,
        state,
        confirmed: false,
        beta,
        oldEmail
      });

      const currentUser = this.auth.getCurrentFirebaseUser();
      this.auth.sendEmailVerification(currentUser).then(() => {
        this.messages.pushMessage('Profile.VerificationEmailSent');
      });

      this.messages.pushMessage('ChangeEmail.Completed');
      this.router.navigateByUrl('/profile');
      this.spinner.hide();
    }
    catch (error) {
      this.spinner.hide();
      if (error.code === 'auth/email-already-in-use') {
        this.messages.pushErrorMessage('ChangeEmail.EmailAlreadyInUse');
      }
      else {
        this.messages.setErrorMessage('ChangeEmail.Failed');
      }
    }
  }
}
